import React from "react";
import { DocumentCard, DocumentCardDetails, DocumentCardTitle } from "@fluentui/react/lib/DocumentCard";
import { CloudFramePrimaryButtonNewButton } from "@cloudframe/button";
import { buttonStyles, cardDetailStyle, cardStyles, titleStyles } from "../styles/CardComponentsStyle";
import { mergeStyles } from "@fluentui/react";
import "./CardComponent.css";

interface CardComponentProps {
  title: string;
  description?: string;
  href?: string;
  buttonText? : string
  onClick? : () => void;
}

const CardComponent: React.FC<CardComponentProps> = ({
  title,
  description,
  href,
  buttonText,
  onClick,
}) => {
    const descriptionStyle = mergeStyles({
        fontSize: "14px", 
      });
  return (
    <div>
      <DocumentCard styles={cardStyles}>
      <div style={{backgroundColor:"#f7f7f7",width:"250px",height:"80px"}}>
        <img src="/assets/Utility.svg" width={60} height={60} style={{marginLeft:"10px",marginTop:"10px"}} alt="Utility Logo"/>
      </div>
      <DocumentCardTitle title={''} styles={titleStyles} shouldTruncate={false}/>
            <DocumentCardDetails styles={cardDetailStyle}>
                <h4>{title}</h4>
                <p style={{fontSize:"14px",marginBottom:"-2px", fontWeight:"400",marginTop:"5px"}}>By CloudFrame</p>
                <p className={descriptionStyle}>{description}</p>
                <CloudFramePrimaryButtonNewButton text= {buttonText} href={href} styles={buttonStyles} onClick={onClick}/>
            </DocumentCardDetails>
      </DocumentCard>
    </div>
  );
};

export default CardComponent;
