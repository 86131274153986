import { useAuth0 } from "@auth0/auth0-react";
import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { CloudFramePageFooter } from "@cloudframe/page-footer";
import { CloudFrameSidenav } from "@cloudframe/sidenav";
import { INavLinkGroup, INavStyles } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import CardComponent from "../../components/card/CardComponent";
import "./Facade.css";
const description = [
  "This utility can be used to read and interpret trace files generated by IBM Db2. It extracts details from db2 trace files and generates detailed report in human readable format, for example all SQL statements run their related SQL Code, Connections, Threads, Predicates and the values set for these predicates, and output related to these statements. ",
  "CloudFrame's CfEditor simplifies viewing EBCDIC data, overcoming common tool limitations like Notepad. It also facilitates the viewing of VSAM DB clusters after migration from CloudFrame servers. CfEditor converts EBCDIC files for easy access.",
  "CloudFrame's File Comparator simplifies comparision of the mainframe output file and java output file to verify the differences between the two files. This tool helps to debug the output mismatches of Cloudframe generated java code. The user can view the changes of both files in a split view or one below another",
  "This utility automates the documentation of Java and Cobol code. It takes your Java or Cobol file as input and generates auto-documented Java code, complete with JavaDoc comments.",
  "CFUtils is built like a java application. We run the jar to start the server. When the server is up, user can browse base URL (e.g. localhost:8080) to surf the application. For the base URL one html page (index.html) is loaded in the browser. This page contains single-page app frontend of CFUtils app.",
  "Call Chain Viewer delivers advanced analysis of program call chains to power better decisions for its users",
  "CF Discovery Utility provides assessment for the cost and resource requirements in Azure for a migrated application",
];
const navStyles: Partial<INavStyles> = {
  root: {
    height: "100%",
    overflowY: "auto",
    marginRight: "20px",
    width: "300px",
  },
  link: {
    color: "black",
    backgroundColor: "white",
  },
};
interface Tab {
  id: number;
  title: string;
  icon: string;
  navKey: string;
}
const Facade: React.FC = () => {
  const [url, setUrl] = useState("");
  const [isFrameDisplay, setFrameDisplay] = useState(false);
  const [hideScrollBar, setHideScrollBar] = useState(false);
  const [activeTab, setActiveTab] = useState<string | undefined>("1");
  const [tabs, setTabs] = useState<Tab[]>([
    { id: 1, title: "Home", icon: "/assets/Home.png", navKey: "0" },
  ]);
  const [currentTab, setCurrentTab] = useState<number | null>(1);
  const [currentTabDisplay, setCurrentTabDisplay] = useState("");
  const [discoveryMsgInterval, setDiscoveryMsgInterval] = useState<NodeJS.Timer>();
  const {isAuthenticated, logout, user } = useAuth0();
  const displayFrame = (url: string) => {
    setUrl(url);
    setFrameDisplay(true);
    // setHideScrollBar(true);
  };

  const createTab = (title: any, icon: string, navKey: string) => {
    const newTab = {
      id: Date.now(),
      title: title,
      icon: icon,
      navKey: navKey,
    };
    setTabs([...tabs, newTab]);
    setCurrentTab(newTab.id);
  };
  const handleTabrowsing = (tabName: string) => {
    if (tabName === "CF Editor") {
      setFrameDisplay(true);
      console.log(displayFrame);
      setUrl("http://training.cloudframe.com:6998/");
    }
  };
  const handleTabClick = (tabId: number, tabName: string) => {
    setCurrentTab(tabId);
    handleTabrowsing(tabName);
  };
  const closeTab = (tabId: number) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== tabId);

    if (tabId === currentTab) {
      // If the active tab is closed, set the first tab as active
      setCurrentTab(updatedTabs.length > 0 ? updatedTabs[0].id : null);
    }
    setTabs(updatedTabs);
  };
  const navLinkGroups: INavLinkGroup[] = [
    {
      name: "Browse Apps",
      expandAriaLabel: "",
      collapseAriaLabel: "",
      links: [
        {
          key: "1",
          name: "Get Started",
          url: "",
          onClick: () => {
            setActiveTab("1");
            setCurrentTab(1);
            setFrameDisplay(false);
          },
        },
        {
          key: "2",
          name: "Scanning",
          url: "",
          onClick: () => {
            setActiveTab("2");
            setCurrentTab(1);
            setFrameDisplay(false);
          },
        },
        {
          key: "3",
          name: "Parsing",
          url: "",
          onClick: () => {
            setActiveTab("3");
            setCurrentTab(1);
            setFrameDisplay(false);
          },
        },
        {
          key: "4",
          name: "File Utilities",
          url: "",
          onClick: () => {
            setActiveTab("4");
            setCurrentTab(1);
            setFrameDisplay(false);
          },
        },
      ],
    },
  ];
  useEffect(() => {
    const messageHandler = (event: any) => {
      if (event.data === "reload") {
        window.location.reload();
      }
    };
    window.addEventListener("message", messageHandler);
  }, []);
  useEffect(() => {
    if (isFrameDisplay) {
      setHideScrollBar(true);
    } else {
      setHideScrollBar(false);
    }
  }, [isFrameDisplay, hideScrollBar]);
  function openNewTab(url:any, title:any) {
    const newTab = window.open(url, '_blank');
    if(newTab)
      newTab.document.title = title;
    console.log(newTab?.document.title)
    return newTab;
  }
  return (
    <>
    {isAuthenticated && (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflow: hideScrollBar ? "hidden" : "auto",
      }}
    >
      {!isFrameDisplay && (
        <div>
          <CloudFrameDefaultHeader title="Peripherals' Appstore" 
           headerConfig={[]}
           linkComponent={Link}
           backLink={{
             label: "Log Out",
             onClick() {
              logout({ logoutParams: { returnTo: window.location.origin } })
             },
             href : "/",
             style: {
               background: "transparent",
               color: "white",
               marginRight: "1.5rem",
               borderRadius: "2px",
               paddingTop: ".25rem",
               paddingBottom: ".25rem",
               fontWeight: "500",
               fontSize: "15px",
             },
           }}/>
          <div style={{ display: "flex" }}>
            <CloudFrameSidenav
              styles={navStyles}
              selectedKey={activeTab}
              className="sideNav"
              groups={navLinkGroups}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflowY: "auto",
              }}
            >
              <div className="tab-display">
                <div
                  onClick={() => {
                    handleTabClick(1, "");
                    setFrameDisplay(false);
                  }}
                  className={1 === currentTab ? "tabs current-tab" : "tabs"}
                  title={tabs[0].title}
                >
                  <div className="tab-content">
                    <img
                      src={tabs[0].icon}
                      alt={tabs[0].title}
                      className="tab-icon"
                    />
                    <span className="tab-title">{tabs[0].title}</span>
                  </div>
                </div>
                {tabs
                  .filter((tab) => tab.navKey === activeTab)
                  .map((tab) => (
                    <div
                      key={tab.id}
                      onClick={() => handleTabClick(tab.id, tab.title)}
                      className={
                        tab.id === currentTab ? "tabs current-tab" : "tabs"
                      }
                      title={tab.title}
                    >
                      <div className="tab-content">
                        <img
                          src={tab.icon}
                          alt={tab.title}
                          className="tab-icon"
                        />
                        <span className="tab-title">{tab.title}</span>
                        {tab.id !== 1 && tab.id === currentTab && (
                          <span
                            className="close-button"
                            onClick={() => closeTab(tab.id)}
                          >
                            {" "}
                            X
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              {!isFrameDisplay && (
                <div className="card-display">
                  {activeTab === "3" && (
                    <CardComponent
                      title="DB2 Trace Parser"
                      description={description[0]}
                      buttonText="Launch Application"
                      onClick={() => {
                       openNewTab("https://utilities-dbfileparser.cloudframe.com/","Utilities.com")
                      }}
                    />
                  )}
                  {activeTab === "4" && (
                    <CardComponent
                      title="CF Editor"
                      description={description[1]}
                      buttonText="Launch Application"
                      onClick={() => {
                        openNewTab("https://utilities-cfeditor.cloudframe.com/","Utilities.com")
                      }}
                    />
                  )}
                  {activeTab === "4" && (
                    <CardComponent
                      title="File Comparator"
                      description={description[2]}
                      buttonText="Launch Application"
                      onClick={() => {
                        openNewTab("https://utilities-filecompare.cloudframe.com/","Utilities.com");
                      }}
                    />
                  )}
                  {activeTab === "1" && (
                    <>
                      <CardComponent
                        title="Call Chain Viewer"
                        description={description[5]}
                        buttonText="Launch Application"
                        onClick={() => {;
                          openNewTab("https://utilities-callchain.cloudframe.com/","Utilities.com");
                        }}
                      />
                      <CardComponent
                        title="CF Discover"
                        description={description[6]}
                        buttonText="Launch Application"
                        onClick={() => {
                          let userId = '';
                          if (user && user.sub) {
                            userId = user?.email ?? user?.sub?.split("|")[1];
                          }
                          const discoverUrl = process.env.REACT_APP_URL_CF_DISCOVER ?? '';
                          const newWindow = openNewTab(discoverUrl, "CF Discover")?.window;
                          if (discoveryMsgInterval) {
                            window.clearInterval(discoveryMsgInterval);
                          }
                          const newInterval = setInterval(() => {
                            try {
                              if (newWindow?.postMessage) {
                                newWindow?.postMessage(userId, discoverUrl);
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }, 1000);
                          setDiscoveryMsgInterval(newInterval);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <footer className="facade-footer">
            <CloudFramePageFooter id="footer" testId="footerTestId">
              {[
                <h3>CloudFrame - Peripheral Tools</h3>,
                <p>Copyright @ 2023</p>,
              ]}
            </CloudFramePageFooter>
          </footer>
        </div>
      )}
      {isFrameDisplay && (
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ height: "100%", width: "100%" }}>
            <Iframe
              url={url}
              height="100%"
              width="100%"
              allowFullScreen={true}
              onLoad={() => setHideScrollBar(true)}
            />
          </div>
        </div>
      )}
    </div>
    )}
    </>
  );
};
export default Facade;