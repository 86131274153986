import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createStore } from 'redux'; 
import { Provider } from 'react-redux';
import rootReducer from './store/store';

import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const store = createStore(rootReducer);
root.render(
  <>
    <Auth0Provider
    domain="dev-vz7onvuz8r5hzcip.us.auth0.com"
    clientId="wBYCZRPq4zXFKSxkwUkmKxoKQwgWA8AK"
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URL
    }}
    >
        <Provider store={store} >
        <App />
        </Provider>
    </Auth0Provider>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals