import React, { useEffect, useState } from "react";
import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { EyeInvisibleOutlined , EyeOutlined } from "@ant-design/icons";
import "./Login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TextInput from "../../components/text/TextInput";
import { useDispatch } from "react-redux";
import { isLoginSucces } from "../../store/store";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  useEffect(()=>{
    loginWithRedirect({
        
    })
  },[loginWithRedirect])
  return (
    <div className="back-color">
    
  </div>
   
  )
};

export default Login;