import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./forgotPass.css"
import { CloudFrameDefaultHeader } from '@cloudframe/header';
import { EyeInvisibleOutlined , EyeOutlined } from "@ant-design/icons";

const ForgotPass: React.FC = () => {
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setNewPassword] = useState(""); 
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState("");
  const [isTokenReceived, setIsTokenReceived] = useState(false); 
  const [isReset, setResetMessage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
 
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/;

    if (!passwordPattern.test(newPassword)) {
      setPasswordValidationMessage("Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-12 characters long.");
    } else {
      setPasswordValidationMessage("");
    }
  };

  const handleMessage = () => {
    alert("Password Reset Successfull")
  }

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    setIsFormSubmitted(true)
    try {
      const response = await axios.post("http://deploy.cloudframe.com:8700/reset-password", {
        email,
        newPassword 
      });
      if (response.status === 200) {
        setResetSuccess(true);
        setIsTokenReceived(false);
      } else {
        setResetError("Password reset request failed. Please try again.");
      }
    } catch (error) {
      console.error('Error:', error);
      setResetError("An error occurred. Please try again later.");
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    if (passwordValidationMessage) {
      return;
    } 
    handleResetPassword(event);
  };
  
  return (
    <div className="password-reset-box">
    <CloudFrameDefaultHeader title="Peripherals' Appstore" />
    <div className='forgotpass-card'>
    <h2 className='forgot-header'>Reset Password</h2>
    {resetSuccess ? (
      <div>
        <p className='instruct'>Instructions to reset your password have been sent to your email.</p>
        <form onSubmit={handleFormSubmit}>
          <div>
            <label className='forgot-label'>Enter Token</label>
            <input type="text" value={resetToken} onChange={(e) => setResetToken(e.target.value)} className='textBox' placeholder='Enter Token...' required />
          </div>
          <div style={{marginTop : '15px'}}>
            <label className='forgot-label'>New Password</label>
            <input type={visible ? "text" : "password"} value={newPassword} onChange={handlePasswordChange} className='textBox' placeholder='Enter New Password...' required />
            <div className="eye-icon-forgot" onClick={() => setVisible(!visible)}>{ visible ? <EyeOutlined /> : <EyeInvisibleOutlined/> }</div>
            {isFormSubmitted && passwordValidationMessage && (
                <p style={{ fontFamily: 'Open Sans', color: 'red', fontSize: '14px' }}>{passwordValidationMessage}</p>
               )}
          </div>
          <div className="forgot-button-container">
          <button type="submit" onClick={handleMessage} className='forgot-token'>Reset Password</button>
          </div>
        </form>
        <div className='return-login'>
        <Link to="/" className='forgot-password-link'>Return to login</Link>
        </div>
      </div>
    ) : (
      <form onSubmit={handleResetPassword}>
        <div>
          <label className='forgot-label'>Email Address</label>
          <input type="email" value={email} onChange={handleEmailChange} className='textBox' placeholder='Email Address...' required />
        </div>
        <div className='forgot-button-container'>
        <button type="submit" className='forgot-token'>Get Token</button>
        </div>
        <div className='return-login'>
        <Link to="/" className='forgot-password-link'>Return to login</Link>
        </div>
      </form>
    )}
    {resetError && (
      <p style={{ color: 'red', fontSize: '16px' }}>{resetError}</p>
    )}
    </div>
  </div>
  );
};

export default ForgotPass;