import './App.css';
import Login from './pages/login/Login';
import { Navigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './pages/signup/Signup';
import { createStore } from 'redux';
import rootReducer from './store/store';
import { Provider } from 'react-redux';
import Facade from './pages/facade/Facade';
import ForgotPass from './pages/login/forgotPass';
import { useSelector } from 'react-redux';
function App() {
  const store = createStore(rootReducer);
  return (
    <Provider store={store}>
    <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/facade" element={<Facade/>}/>
      <Route path="/forgot-password" element={<ForgotPass />} /> 
    </Routes>
    </Router>
      </Provider>
  );
}

export default App;