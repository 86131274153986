import { IButtonStyles, IDocumentCardDetailsStyles, IDocumentCardStyles, IDocumentCardTitleStyles } from "@fluentui/react";

export const cardStyles : IDocumentCardStyles = {
    root: {
      width: "250px",
      height: "450px",
      display: "inline-blocck",
      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
      transition: "transform 0.1s ease", 
      "&:hover": {
        transform: "scale(1.01)", 
      },
    },
  };

export const titleStyles : IDocumentCardTitleStyles = {
    root: {
      display: 'none',
      textAlign: "center",
      backgroundColor: "#f7f7f7",
      lineHeight: "35px",
      color: "#2874FC",      
      fontWeight: "700",
      height:"50px"
    },
  };

export const cardDetailStyle: IDocumentCardDetailsStyles = {
    root: {
      textAlign: "justify",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  };
 
export const buttonStyles: IButtonStyles = {
    root: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      width:"250px",
      transform: 'translateX(-50%)',
      marginBottom: '10px',
      color:"#3364D9",
      backgroundColor:"white",
      border:"none",
      borderTop:"2px #f7f7f7",
    },
  };