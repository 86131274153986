import React, { useState } from "react";
import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { Link , useNavigate } from "react-router-dom";
import axios from "axios";
import { EyeInvisibleOutlined , EyeOutlined } from "@ant-design/icons";
import "./Signup.css";
import TextInput from "../../components/text/TextInput";
import UserInput from "../../components/text/UserInput";
const Signup: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  // const [select, setSelects] = useState("");
  // const [otherValue, setOtherValue] = useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [emailValidate, setEmailValidate] = useState("")
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMail = event.target.value;
    setEmail(newMail)

    const emailPattern =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@cloudframe\.com$/
    if(!emailPattern.test(newMail)) {
      setEmailValidate("Invalid Email format")
    } else {
      setEmailValidate("")
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/;

    if (!passwordPattern.test(newPassword)) {
      setPasswordValidationMessage("Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-12 characters long.");
    } else {
      setPasswordValidationMessage("");
    }
  };

  // const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedOption = event.target.value;
  //   setSelects(selectedOption);

  //   if (selectedOption !== "option4") {
  //     // If an option other than "Others" is selected, clear the input field value.
  //     setOtherValue("");
  //   }
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    try {
      const userData = {
        username: username,
        email: email,
        password: password,
        // select: select,
        // ...(select === "option4" ? { otherValue: otherValue } : {}),
      };
      const response = await axios.post("https://dev-utilities.cloudframe.com/signup", userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
        if (response.status === 201) {
          console.log("User created successfully");
          navigate("/", { state: { signupSuccess: true } });
        } else if (response.status === 200) {
          const errorMessageFromServer = response.data.message; // Extract the error message from the response
          setErrorMessage(errorMessageFromServer);
        }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    if (passwordValidationMessage) {
      return;
    } 
    if (emailValidate) {
      return
    }
    handleSubmit(event);
  };
  
  return (
    <div className="back-color">
      <div className="login">
        <CloudFrameDefaultHeader title="Peripherals' Appstore" />
        <div className="login-card">
          <h2 className="header">Create an Account</h2>
          <div>
            <form onSubmit={handleFormSubmit}>
              <div className="input">
                <div className="input-container-1">
                  <UserInput label="Email Address" id="email" type="text" value={email} placeholder="Email Address..." onChange={handleEmailChange} />
                  {isFormSubmitted && emailValidate && (
                   <p style={{ fontFamily: 'Open Sans', color: 'red', fontSize: '14px' }}>{emailValidate}</p>
                  )}
                  {isFormSubmitted && errorMessage && <p style={{ fontFamily: 'Open Sans', color: 'red', fontSize: '14px' , marginBottom : '-7px'}}>{errorMessage}</p>}
                </div>
                <div className="input-container-2">
                  <UserInput label="Username" id="username" type="text" value={username} placeholder="Username..." onChange={handleUserNameChange} />
                </div>
              </div>
              <div className="pass">
              <label className="label">Password</label>
              <input id="password" type={visible ? "text" : "password"} className="textBox" value={password} placeholder="Password..." onChange={handlePasswordChange}/>
              <div className="eye-icon-signup" onClick={() => setVisible(!visible)}>{ visible ? <EyeOutlined /> : <EyeInvisibleOutlined/> }</div>
              {isFormSubmitted && passwordValidationMessage && (
                <p style={{ fontFamily: 'Open Sans', color: 'red', fontSize: '14px' }}>{passwordValidationMessage}</p>
               )}
              </div>
              {/* <div className="dropdown-container">
                <label className="label">How did you hear about the Peripherals' Appstore ?</label>
                <select value={select} onChange={handleOptionChange} className="dropdown" id="myDropdown" required >
                  <option value="" disabled selected>Select an option</option>
                  <option value="I am a CloudFrame Employee">I am a CloudFrame Employee</option>
                  <option value="I am a CloudFrame Partner">I am a CloudFrame Partner</option>
                  <option value="I am a CloudFrame Customer">I am a CloudFrame Customer</option>
                  <option value="other">Other</option>
                </select>
                <div className="others">
                {select === "other" && ( <input type="text" className="textBox" placeholder="Please specify"
                    value={otherValue}
                    onChange={(e) => setOtherValue(e.target.value)}
                  />
                )}
                </div>
              </div> */}
              <div>
                <button
                  className="signup"
                >Signup</button>
              </div>
              <div className="button-container">
                <p className="account">
                  Have an account ? <Link to="/">
                    <button type="submit" className="signin-button"> Sign in </button>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;