import React, { ChangeEvent } from 'react';

interface UserInputProps {
  label: string;
  id: string;
  type: string;
  value: string;
  placeholder :string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const UserInput: React.FC<UserInputProps> = ({ label, id, type, value, placeholder, onChange }) => (
  <div className="input-fields">
    <label htmlFor={id}>{label}</label>
    <input className="inputBox" type={type} id={id} value={value} placeholder={placeholder} onChange={onChange} required />
  </div>
);

export default UserInput;