export const isLoginSucces = (value:any) => {
    return {
        type: "UPDATE_LOGIN_STATUS",
        payload: value
    }
}

const initialState = {
    loginStatus : false
}

const rootReducer:any = (state = initialState, action:any)  => {
    switch(action.type) {
        case "UPDATE_LOGIN_STATUS":
            return {
                ...state,
                loginStatus: action.payload,
            }
        default:
            return state

    }
}

export default rootReducer;